.header {
	background-color: $background-color-aside;
	position: fixed;
	height: 100vh;
	width: 300px;
	z-index: 10;
	overflow: auto;
	@include _768 {
		left: -100%;
		width: 100%;
		height: 100vh;
		visibility: hidden;
		transition: all 0.8s;
	}
	&.active {
		left: 0;
		visibility: visible;
		opacity: 1;
		overflow-y: scroll;
	}
	&__nav {
		&-list {
			margin-left: -7px;
			margin-top: 90px;
			width: max-content;
			@include _1200 {
				margin-top: 20px;
			}
			@media (max-height: 700px) {
				margin-top: 20px;
			}
		}
		&-item {
			margin-top: 39px;
			@include _1200 {
				margin-top: 10px;
				font-size: 17px;
			}
			@media (max-height: 700px) {
				margin-top: 10px;
				font-size: 17px;
			}
		}
		&-link {
			color: $white;
			padding: 7px;
			&:hover {
				background-color: $orange;
				transition: all 0.8s;
			}
		}
	}
	&__wrapper {
		&-phone {
			color: $orange-text;
		}
		&-email {
			color: $white;
			display: block;
			width: max-content;
		}
		&-social {
			display: flex;
			grid-gap: 20px;
			margin-top: 30px;
		}
		&-item {
			cursor: pointer;
			background: rgba(255, 255, 255, 0.1);
			border-radius: 15px;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 3px;
			&:hover {
				background-color: $orange;
				transition: all 0.8s;
			}
		}
	}
	&__close {
		cursor: pointer;
		position: absolute;
		top: 5px;
		right: 5px;
		padding: 25px;
		display: none;
		@include _768 {
			display: block;
		}
		&::before,
		&::after {
			position: absolute;
			content: ' ';
			height: 55px;
			width: 4px;
			background-color: $orange;
			top: -2px;
			right: 22px;
		}
		&::after {
			transform: rotate(-45deg);
		}
		&::before {
			transform: rotate(45deg);
		}
	}
}

.container-header {
	max-width: 300px;
	margin: 0 auto;
	padding: 60px 40px 30px 40px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: inherit;
}
