.title {
	&_fz110 {
		font-family: 'Muller';
		font-weight: bold;
		font-size: 110px;
		line-height: 120%;
		@include _fz110;
	}
	&_fz24-fw400 {
		font-family: 'Muller';
		font-size: 24px;
		line-height: 120%;
		font-weight: normal;
		@include _fz24;
	}
	&_fz24-fw700 {
		font-family: 'Muller';
		font-size: 24px;
		line-height: 120%;
		font-weight: bold;
		@include _fz24;
	}
	&_fz24 {
		font-family: 'Muller';
		font-size: 24px;
		line-height: 120%;
		font-weight: 500;
		@include _fz24;
	}
	&_fz44 {
		font-family: 'Muller';
		font-size: 44px;
		line-height: 120%;
		font-weight: bold;
		&--modal {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			color: $white;
		}
		@include _fz44;
	}
	&_fz20-fw400 {
		font-family: 'Muller';
		font-weight: normal;
		font-size: 20px;
		line-height: 120%;
		@include _fz20;
	}
	&_fz20 {
		font-family: 'Muller';
		font-weight: 500;
		font-size: 20px;
		line-height: 120%;
		@include _fz20;
	}
	&_fz14 {
		font-family: 'Muller';
		font-weight: normal;
		font-size: 14px;
		line-height: 130%;
		@include _fz14;
	}
	&_fz20-Nekst {
		font-family: 'Nekst';
		font-size: 20px;
		line-height: 150%;
		font-weight: bold;
		@include _fz20;
	}
}
