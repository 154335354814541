.container {
	max-width: 1000px;
	margin: 0 auto;
	padding: 0 30px;
}

.visually-hidden {
	visibility: hidden;
	position: absolute;
	clip: rect(0 0 0 0);
	width: 0px;
	height: 0px;
	margin: -1px;
}

main {
	padding-left: 300px;
	@include _768 {
		padding-left: 0px;
	}
}
