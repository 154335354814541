.form {
    padding: 85px 0;
    background-image: url("../images/Rectangle-39397.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #181d27;
        opacity: 0.9;
    }
    &__title {
        margin-bottom: 55px;
        @media (max-width: 500px) {
            margin-bottom: 35px;
        }
    }
    .form__form {
        z-index: 1;
        max-width: 827px;
        width: 100%;
        color: $white;
        padding: 70px 100px;
        background: $background-color-aside;
        opacity: 0.9;
        border-radius: 30px;
        @media (max-width: 1200px) {
            padding: 60px 50px;
        }
        @media (max-width: 500px) {
            padding: 40px 20px;
        }
    }
    &__input,
    &__textarea,
    &__file {
        border: 1px solid $white;
        box-sizing: border-box;
        border-radius: 10px;
        background: transparent;
        width: 100%;
        display: inline-block;
        padding: 23px 25px;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 15px;
    }
    &__textarea {
        resize: none;
    }
    &__file {
        border: 1px dashed $white;
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
    }
    &__btn {
        background: #ff8801;
        border-radius: 10px;
        color: $white;
        border: none;
        padding: 21px;
        display: inline-block;
        width: 100%;
        margin-top: 50px;
        transition: all ease 0.7s;
        &:hover {
            background: #ffa53f;
        }
    }
    &__txt {
        color: rgba(255, 255, 255, 0.75);
        text-align: center;
        max-width: 330px;
        margin: 0 auto;
        margin-top: 20px;
    }
    &__link {
        color: inherit;
        transition: all ease 0.7s;
        &:hover {
            color: $white;
        }
    }
}
