.clubs {
	&__list {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		@media (max-width: 1200px) {
			grid-template-columns: 1fr 1fr;
		}
		// grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
		&-text {
			color: #ffffff;
			position: absolute;
			bottom: 70px;
			left: 50px;
		}
		&-img {
			width: 100%;
		}
		&-link {
			position: relative;
			display: block;
			&::before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: linear-gradient(
					180deg,
					rgba(24, 29, 39, 0) 8.79%,
					rgba(24, 29, 39, 0.624219) 60.57%,
					rgba(24, 29, 39, 0.96) 100%
				);
			}
		}
	}
}
