/* Core variables */
@import 'base/vars';

/* CSS Reset */
@import 'base/reset';

/* Fonts */
@import 'base/fonts';

/* Mixins */
@import 'base/mixins';

/* Title */
@import 'base/title';

/* Global */
@import 'base/global';

/* Animate */
@import 'base/animate';

/* Page */
@import 'page/header';
@import 'page/banner';
@import 'page/parametrs';
@import 'page/buns';
@import 'page/projects';
@import 'page/clubs';
@import 'page/form';