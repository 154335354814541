.banner {
	background-image: url('../images/back.jpg');
	background-repeat: no-repeat;
	min-height: 100vh;
	background-position: center;
	background-size: cover;
	padding-top: 152px;
	&__title {
		color: $white;
	}
	&__line {
		margin-top: 50px;
		border-bottom: 2px solid $white;
		max-width: 675px;
	}
	&__subtitle {
		color: $orange-text;
		margin-top: 50px;
		text-transform: uppercase;
	}
}

.hamburger {
	display: none;
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
	@include _768 {
		display: block;
	}
	span {
		display: block;
		width: 45px;
		height: 3px;
		background: $orange;
		&:nth-child(2) {
			margin-top: 5px;
		}
		&:nth-child(3) {
			margin-top: 5px;
		}
	}
}
