@font-face {
	font-family: 'Muller';
	src: url('../fonts/MullerRegular.eot');
	src: local('Muller Regular'), local('MullerRegular'),
		url('../fonts/MullerRegular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MullerRegular.woff2') format('woff2'),
		url('../fonts/MullerRegular.woff') format('woff'),
		url('../fonts/MullerRegular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Muller';
	src: url('../fonts/MullerMedium.eot');
	src: local('../fonts/Muller Medium'), local('MullerMedium'),
		url('../fonts/MullerMedium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MullerMedium.woff2') format('woff2'),
		url('../fonts/MullerMedium.woff') format('woff'),
		url('../fonts/MullerMedium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Muller';
	src: url('../fonts/MullerBold.eot');
	src: local('Muller Bold'), local('MullerBold'),
		url('../fonts/MullerBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MullerBold.woff2') format('woff2'),
		url('../fonts/MullerBold.woff') format('woff'),
		url('../fonts/MullerBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nekst';
	src: url('../fonts/Nekst-Bold.eot');
	src: local('../Nekst Bold'), local('Nekst-Bold'),
		url('../fonts/Nekst-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Nekst-Bold.woff2') format('woff2'),
		url('../fonts/Nekst-Bold.woff') format('woff'),
		url('../fonts/Nekst-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
