@mixin _1600 {
	@media (max-width: 1600px) {
		@content;
	}
}
@mixin _1360 {
	@media (max-width: 1360px) {
		@content;
	}
}
@mixin _1200 {
	@media (max-width: 1200px) {
		@content;
	}
}
@mixin _1024 {
	@media (max-width: 1024px) {
		@content;
	}
}
@mixin _768 {
	@media (max-width: 768px) {
		@content;
	}
}
@mixin _568 {
	@media (max-width: 568px) {
		@content;
	}
}
@mixin _400 {
	@media (max-width: 400px) {
		@content;
	}
}
@mixin _350 {
	@media (max-width: 350px) {
		@content;
	}
}

@mixin _fz110 {
	@include _1600 {
		font-size: 90px;
	}
	@include _1360 {
		font-size: 90px;
	}
	@include _1200 {
		font-size: 80px;
	}
	@include _1024 {
		font-size: 70px;
	}
	@include _768 {
		font-size: 60px;
	}
	@include _568 {
		font-size: 50px;
	}
	@include _400 {
		font-size: 40px;
	}
	@include _350 {
		font-size: 40px;
	}
}

@mixin _fz24 {
	@include _1600 {
		font-size: 24px;
	}
	@include _1360 {
		font-size: 24px;
	}
	@include _1200 {
		font-size: 22px;
	}
	@include _1024 {
		font-size: 20px;
	}
	@include _768 {
		font-size: 18px;
	}
	@include _568 {
		font-size: 16px;
	}
	@include _400 {
		font-size: 15px;
	}
	@include _350 {
		font-size: 14px;
	}
}

@mixin _fz44 {
	@include _1600 {
		font-size: 40px;
	}
	@include _1360 {
		font-size: 40px;
	}
	@include _1200 {
		font-size: 35px;
	}
	@include _1024 {
		font-size: 33px;
	}
	@include _768 {
		font-size: 30px;
	}
	@include _568 {
		font-size: 28px;
	}
	@include _400 {
		font-size: 25px;
	}
	@include _350 {
		font-size: 22px;
	}
}

@mixin _fz20 {
	@include _1600 {
		font-size: 20px;
	}
	@include _1360 {
		font-size: 20px;
	}
	@include _1200 {
		font-size: 18px;
	}
	@include _1024 {
		font-size: 16px;
	}
	@include _768 {
		font-size: 15px;
	}
	@include _568 {
		font-size: 14px;
	}
	@include _400 {
		font-size: 13px;
	}
	@include _350 {
		font-size: 12px;
	}
}

@mixin _fz14 {
	@include _1600 {
		font-size: 14px;
	}
	@include _1360 {
		font-size: 14px;
	}
	@include _1200 {
		font-size: 14px;
	}
	@include _1024 {
		font-size: 14px;
	}
	@include _768 {
		font-size: 13px;
	}
	@include _568 {
		font-size: 12px;
	}
	@include _400 {
		font-size: 11px;
	}
	@include _350 {
		font-size: 11px;
	}
}


