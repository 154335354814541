.projects {
	background-color: $background-color;
	padding-top: 137px;
	padding-bottom: 215px;
	@include _1200 {
		padding-bottom: 120px;
		padding-top: 150px;
	}
	@media (max-height: 700px) {
		padding-bottom: 120px;
		padding-top: 150px;
	}
	&__title {
		color: $white;
		text-transform: uppercase;
	}
	&__list {
		margin-top: 176px;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
		position: relative;
		@include _1200 {
			margin-top: 70px;
		}
		@media (max-height: 700px) {
			margin-top: 70px;
		}
		&::after {
			content: "";
			width: 100%;
			position: absolute;
			background-color: $background-color;
			height: 5px;
			bottom: 0;
		}
		:nth-child(even) {
			text-align: right;
			@media (max-width: 1000px) {
				text-align: left;
			}
		}
		&-item {
			color: $white;
			margin-top: 28px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.1);
			padding-bottom: 28px;
		}
	}
	&__wrapper {
		position: relative;
	}
	&__btn {
		background-color: #151922;
		width: 302px;
		height: 302px;
		border-radius: 50%;
		position: absolute;
		top: 0;
		right: 30%;
		cursor: pointer;
		@include _1200 {
			width: 150px;
			height: 150px;
			top: 26%;
			right: 36%;
		}
		@media (max-width: 1000px) {
			top: 103%;
			left: 0;
		}
		&:hover {
			transition: all 0.8s;
			background-color: $orange;
			.projects__btn-arrow {
				opacity: 1;
				transition: all 0.8s;
			}
		}
		&-cirlce {
			position: absolute;
			left: 28px;
			top: 28px;
			@include _1200 {
				width: 79%;
				left: 15px;
				top: -47px;
			}
		}
		&-arrow {
			position: absolute;
			top: 40%;
			left: 37%;
			opacity: 0;
			@include _1200 {
				width: 32%;
				top: 33%;
				left: 34%;
			}
		}
	}
}
