@keyframes circling {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

//circling
.circle {
	animation-name: circling;
	animation-duration: 20s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}
