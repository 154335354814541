.buns {
	background-color: $background-color;
	padding-top: 137px;
	padding-bottom: 215px;
	@include _1200 {
		padding-bottom: 120px;
		padding-top: 77px;
	}
	@media (max-height: 700px) {
		padding-bottom: 30px;
		padding-top: 90px;
	}
	&__title {
		color: $white;
		text-transform: uppercase;
	}
	&__subtitle {
		color: $orange-text;
	}
	&__list {
		display: grid;
		grid-gap: 60px;
		grid-template-columns: 1fr 1fr;
		margin-top: 74px;
		@include _568 {
			grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		}
		&-icon {
			@media (max-width: 980px) {
				width: 60px;
			}
		}
		&-item {
			display: flex;
			align-items: center;
		}
		&-text {
			color: $white;
			margin-left: 30px;
		}
	}
}
